// import React, { useState } from 'react';
// import { Navbar, Nav, Badge, Image, Dropdown } from 'react-bootstrap';
// import { FaBell } from 'react-icons/fa';
// import './styles/Navbar.css';
// import { useNavigate } from 'react-router-dom';

// const TopNavbar = ({ toggleSidebar, notificationsCount, username }) => {
//   const navigate = useNavigate();
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//   const handleNavbarToggle = () => {
//     setIsNavbarOpen(!isNavbarOpen);
//   };

//   const handleLogout = () => {
//     navigate('/');
//   };

//   const handleNavbarAndSidebarToggle = () => {
//     handleNavbarToggle();
//     toggleSidebar();
//   };

//   return (
//     <Navbar variant="light" expand="lg" fixed="top" className="top-navbar" style={{ backgroundColor: "rgba(235, 235, 235)" }}>
//       <Navbar.Brand href="#"><h4>AB Hospital</h4></Navbar.Brand>
//       <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavbarAndSidebarToggle} />
//       <Navbar.Collapse id="basic-navbar-nav" className={isNavbarOpen ? 'show' : ''}>
//         <Nav className="align-items-center ml-auto">
//           <Nav.Item className="position-relative bell-icon">
//             <FaBell size={24} color="black" />
//             {notificationsCount > 0 && (
//               <Badge pill bg="danger" className="position-absolute" style={{ top: '-5px', right: '1px' }}>
//                 {notificationsCount}
//               </Badge>
//             )}
//           </Nav.Item>
//           <Dropdown align="end" className="profile-section">
//             <Dropdown.Toggle variant="link" className="d-flex align-items-center p-0 text-dark">
//               <Image src="/static/hospital-CRM.jpg" roundedCircle width="50" height="30" className="mr-2" />
//               <span className="text-dark">{username || 'USERNAME'}</span>
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu-right">
//               <Dropdown.Item href="#">Profile</Dropdown.Item>
//               <Dropdown.Item href="#">Settings</Dropdown.Item>
//               <Dropdown.Divider />
//               <Dropdown.Item href="#" onClick={handleLogout}>Logout</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default TopNavbar;



import React, { useState } from 'react';
import { Navbar, Nav, Form, FormControl, Badge, Image, Dropdown } from 'react-bootstrap';
import { FaBell, FaSearch, FaBars } from 'react-icons/fa';
import './styles/Navbar.css';
import { useNavigate } from 'react-router-dom';

const TopNavbar = ({ toggleSidebar, notificationsCount, username }) => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleLogout = () => {
    navigate('/');
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <Navbar variant="light" fixed="top" className="top-navbar">
      <div className="d-flex align-items-center">
        {/* Sidebar toggle button for mobile view */}
        <FaBars className="sidebar-toggler d-lg-none" onClick={toggleSidebar} />

        {/* Search Bar */}
        {/* <Navbar.Brand href="#" className="search-bar d-flex align-items-center">
          <FaSearch  className="search-icon" />
          {isSearchOpen && (
            <Form className="d-flex search-form" inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            </Form>
          )}
        </Navbar.Brand> */}
      </div>

      {/* Right-aligned Notification Icon and Profile Section */}
      <Nav className="navbar-nav-right">
        <Nav.Item className="position-relative bell-icon">
          <FaBell size={24} color="black" />
          {notificationsCount > 0 && (
            <Badge pill bg="danger" className="position-absolute" style={{ top: '-5px', right: '1px' }}>
              {notificationsCount}
            </Badge>
          )}
        </Nav.Item>
        <Dropdown align="end" className="profile-section">
          <Dropdown.Toggle variant="link" className="d-flex align-items-center p-0 text-dark">
            <Image src="/static/hospital-CRM.jpg" roundedCircle width="30" height="30" className="mr-2" />
            <span className="text-dark d-none d-md-inline">{username || 'USERNAME'}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="text-dark">
            <Dropdown.Item href="#">Profile</Dropdown.Item>
            <Dropdown.Item href="#">Settings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#" onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

export default TopNavbar;
