import React from 'react';
import './styles/Landingpage.css';
import { Container, Row, Col, Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Landingpage = () => {
  return (
    <div className="landing-page">
      {/* Header */}
      <Navbar expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="#home">Hospital Accounting</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">  {/* ms-auto aligns links to the right */}
              <Nav.Link as={Link} to="#about">About Us</Nav.Link>
              <Nav.Link as={Link} to="#features">Features</Nav.Link>
              <Nav.Link as={Link} to="/Signup">Signup</Nav.Link>
              <Nav.Link as={Link} to="/Login" className="text-white px-3">Login</Nav.Link>
          </Nav>  
      </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className="hero-section">
        <Container className="text-center text-white">
          <h1 className="display-3 font-weight-bold">Streamline Your Hospital Accounting</h1>
          <p className="lead mt-3">Manage patient billing, expenses, and financial reports seamlessly.</p>
          <Button variant="primary" size="lg" href="#features" className="mt-3">Get Started</Button>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section" id="features">
        <Container>
          <h2 className="text-center mb-5">Features</h2>
          <Row>
            <Col md={4} className="feature-item text-center">
              <i className="bi bi-file-earmark-medical feature-icon"></i>
              <h4>Patient Billing</h4>
              <p>Manage patient bills with ease, ensuring transparency and accuracy in all transactions.</p>
            </Col>
            <Col md={4} className="feature-item text-center">
              <i className="bi bi-graph-up feature-icon"></i>
              <h4>Expense Tracking</h4>
              <p>Track hospital expenses in real-time, optimizing budget allocation and resource management.</p>
            </Col>
            <Col md={4} className="feature-item text-center">
              <i className="bi bi-bar-chart-line feature-icon"></i>
              <h4>Reporting & Analytics</h4>
              <p>Gain insights with detailed reports and analytics for better decision-making and planning.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <Container>
          <h2 className="text-center mb-5">What Our Clients Say</h2>
          <Row>
            <Col md={4} className="text-center testimonial-item">
              <p className="testimonial">"An invaluable tool for our hospital's financial management. Highly recommended!"</p>
              <p className="client-name">— Dr. Smith, MedHealth Hospital</p>
            </Col>
            <Col md={4} className="text-center testimonial-item">
              <p className="testimonial">"Helped us streamline billing and save time on monthly reports!"</p>
              <p className="client-name">— Sarah Lee, Green Valley Clinic</p>
            </Col>
            <Col md={4} className="text-center testimonial-item">
              <p className="testimonial">"Very user-friendly and efficient. A must-have for hospital finance teams."</p>
              <p className="client-name">— Mike Chen, Finance Officer</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer */}
      <footer className="footer">
        <Container className="text-center">
          <Row>
            <Col>
              <p>&copy; 2024 Hospital Accounting App</p>
              <Nav className="justify-content-center">
                <Nav.Link href="#privacy" className="text-white">Privacy Policy</Nav.Link>
                <Nav.Link href="#terms" className="text-white">Terms of Service</Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Landingpage;
