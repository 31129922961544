

import React, { useState } from 'react';
import './styles/Signup.css';
import { Link, useNavigate } from 'react-router-dom';

const Signup = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        department: '',
        password: '',
        confirmPassword: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSignup = (e) => {
        e.preventDefault();
        console.log('Signup data:', formData);
        // Navigate to Dashboard or show a success message if signup logic is added.
        navigate("/Dashboard");
    };

    return (
        <div className="signup-container">
            {/* Left side with background image */}
            <div className="signup-image"></div>

            {/* Right side with signup form */}
            <div className="signup-form-container">
                <h2>Signup</h2>
                

                <form className="signup-form" onSubmit={handleSignup}>
                    <div className="form-group">
                        <label>Full Name</label>
                        <input 
                            type="text" 
                            name="fullName" 
                            placeholder="Enter your full name" 
                            value={formData.fullName}
                            onChange={handleInputChange}
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Enter your email" 
                            value={formData.email}
                            onChange={handleInputChange}
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input 
                            type="tel" 
                            name="phoneNumber" 
                            placeholder="Enter your phone number" 
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            required 
                        />
                    </div>
                    {/* <div className="form-group">
                        <label>Department</label>
                        <input 
                            type="text" 
                            name="department" 
                            placeholder="Enter your department" 
                            value={formData.department}
                            onChange={handleInputChange}
                            required 
                        />
                    </div> */}
                    <div className="form-group">
                        <label>Password</label>
                        <input 
                            type="password" 
                            name="password" 
                            placeholder="Enter your password" 
                            value={formData.password}
                            onChange={handleInputChange}
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input 
                            type="password" 
                            name="confirmPassword" 
                            placeholder="Confirm your password" 
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            required 
                        />
                    </div>
                    <button type="submit" className="signup-button">Sign Up</button>
                </form>

                <p className="signup-footer">
                    Already have an account? <Link to="/Login">Login here</Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
