import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Sidebar from './Sidebar';
import TopNavbar from './Navbar';
import './styles/Dashboard.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement
);


const hospitalGrowthData = {
  labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
  datasets: [
    {
      label: "Annual Growth",
      data: [1200000, 1500000, 1700000, 2000000, 2500000, 3000000],
      backgroundColor: "rgba(54, 162, 235, 0.4)",
      borderColor: "rgba(54, 162, 235, 1)",
      fill: true
    }
  ]
};


const income = {
  monthly: {
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datasets: [
      {
        label: "Monthly Income",
        data: [50000, 60000, 70000, 80000, 75000, 90000, 95000, 100000, 85000, 87000, 120000, 130000],
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        fill: true
      }
    ]
  },
  departments: {
    labels: ["Cardiology", "Pediatrics", "Radiology", "Neurology"],
    datasets: [
      {
        data: [200000, 150000, 100000, 170000],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      }
    ]
  }
};

const expenseData = {
  monthly: {
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datasets: [
      {
        label: "Monthly Expenses",
        data: [30000, 25000, 27000, 26000, 31000, 28000, 35000, 34000, 36000, 30000, 32000, 33000],
        backgroundColor: "rgba(255,99,132,0.4)",
        borderColor: "rgba(255,99,132,1)",
        fill: true
      }
    ]
  },
  categories: {
    labels: ["Salaries", "Supplies", "Maintenance", "Utilities", "Other"],
    datasets: [
      {
        label: "Expense Breakdown",
        data: [400000, 100000, 80000, 60000, 40000],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      }
    ]
  }
};

const loginData = {
  labels: ["Doctors", "Nurses", "Accountant", "Receptionist", "Technicians", "Pharmacists"],
  datasets: [
    {
      label: "Logins by Role",
      data: [120, 250, 100, 50, 90, 60],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
    }
  ],
  individual: [
    { name: "Dr. John Doe (Doctor)", logins: 20 },
    { name: "Nurse Jane Smith", logins: 35 },
    { name: "Technician Sam Brown", logins: 25 },
    { name: "Admin Mary Davis", logins: 18 },
    { name: "Accountant Mark Lee", logins: 10 },
    { name: "Pharmacist Lucy Green", logins: 15 },
    { name: "Receptionist Anna White", logins: 22 }
  ]
};
const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [events, setEvents] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' });

  const handleEventSubmit = () => {
    setEvents([...events, { ...newEvent, start: new Date(newEvent.start), end: new Date(newEvent.end) }]);
    setShowEventModal(false);
    setNewEvent({ title: '', start: '', end: '' });
  };
  const localizer = momentLocalizer(moment);
  return (
    <>
    <div className="dashboard-container">
    <TopNavbar toggleSidebar={toggleSidebar} notificationsCount={3} username="User" />
    <Sidebar isSidebarOpen={isSidebarOpen} />

    <div className={`dashboard-content ${isSidebarOpen ? 'content-shifted' : ''}`}>
      <h2 className="text-dark">DASHBOARD</h2>
        
        
        <Row className="mb-5">
          <Col md={3}><Card className="summary-card gradient-yellow"><Card.Body>Revenue: Rs.5,000,000</Card.Body></Card></Col>
          <Col md={3}><Card className="summary-card gradient-pink"><Card.Body>Expenses: Rs.3,000,000</Card.Body></Card></Col>
          <Col md={3}><Card className="summary-card gradient-purple"><Card.Body>Net Profit: Rs.2,000,000</Card.Body></Card></Col>
          <Col md={3}><Card className="summary-card gradient-green"><Card.Body>Receivables: Rs.500,000</Card.Body></Card></Col>
        </Row>
        
        
        <Row className="mb-4 align-items-start">

  <Col md={6} className="mb-4">
    <Card className="chart-card">
      <Card.Body>
        <Card.Title>Department Income Distribution</Card.Title>
        <Pie data={income.departments} />
      </Card.Body>
    </Card>
  </Col>

  
  <Col md={6}>
    <Row className="justify-content-center">
      {income.departments.labels.map((department, index) => (
        <Col xs={12} sm={6} md={6} key={index} className="mb-4">
          <Card
            className="circular-card"
            style={{
              background: income.departments.datasets[0].backgroundColor[index],
            }}
          >
            <Card.Body>
              <Card.Title>{department}</Card.Title>
              <Card.Text>
                Income: Rs. {income.departments.datasets[0].data[index].toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Col>
</Row>


        
        <Row className="mb-4">
          <Col md={6}>
            <Card className="chart-card">
              <Card.Body>
                <Card.Title>Monthly Income Trends</Card.Title>
                <Line data={income.monthly} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="chart-card">
              <Card.Body>
                <Card.Title>Monthly Expenses</Card.Title>
                <Bar data={expenseData.monthly} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        
        <Row className="mb-4">
          <Col md={5}>
            <Card className="chart-card">
              <Card.Body>
                <Card.Title>Login Statistics</Card.Title>
                <Bar data={loginData} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={7}>
            <Row className="d-flex flex-wrap">
              {loginData.labels.map((role, index) => (
                <Col xs={6} sm={4} className="mb-3" key={index}>
                  <Card className="role-card text-light" style={{ backgroundColor: loginData.datasets[0].backgroundColor[index] }}>
                    <Card.Body>
                      <Card.Title>{role}</Card.Title>
                      <Card.Text>Total Logins: {loginData.datasets[0].data[index]}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

    
        <Row className="mb-4">
            <Col md={10}>
              <Card className="chart-card" >
                <Card.Body>
                  <Card.Title>Calendar</Card.Title>
                  <Button variant="primary" onClick={() => setShowEventModal(true)}>
                    Add Event
                  </Button>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 400, marginTop: '20px' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          
          <Modal show={showEventModal} onHide={() => setShowEventModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Event Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={newEvent.title}
                    onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Date and Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={newEvent.start}
                    onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date and Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={newEvent.end}
                    onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEventModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleEventSubmit}>
                Add Event
              </Button>
            </Modal.Footer>
          </Modal>

     </div>
   </div>
    </>
  );

};

export default Dashboard;

