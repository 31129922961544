import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landingpage from './components/Landingpage';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';


function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landingpage />}></Route>
      <Route path="Login" element={<Login />}></Route>
      <Route path="Signup" element={<Signup />}></Route>
      <Route path="Dashboard" element={<Dashboard />}></Route>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
