import React, { useState } from "react";
import "./styles/Loginpage.css";
import { Link,useNavigate } from "react-router-dom";


const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        
        // Here, you could add authentication logic.
        // If the login is successful, navigate to the dashboard.
        if (username && password) { // Placeholder check; replace with actual authentication
            navigate("/Dashboard");  // Redirects to Dashboard
        } else {
            alert("Please enter both username and password.");
        }
    };

    return (
        <div className="login-container">
            {/* Left side with background image */}
            <div className="login-image"></div>

            {/* Right side with login form */}
            <div className="login-form-container">
                <h2>Welcome Back</h2>
                <p>Please sign in to continue</p>

                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter your username"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter your password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="login-button">Login</button>
                </form>

                <p className="login-footer">
                    Don't have an account? <Link to="/Signup">Signup here</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
