


import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Sidebar.css';
import { FaTachometerAlt, FaFileAlt, FaSignOutAlt, FaUser, FaCalendarAlt, FaUserMd, FaCogs } from 'react-icons/fa';

const Sidebar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/'); // Redirect to the landing page
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      {/* Brand Section */}
      <div className="sidebar-brand" style={{ marginTop: "-5vh",paddingLeft:"1vw" }}>
        <h2>AB Hospital</h2>
      </div>

      {/* Navigation Links */}
      <nav>
        <ul>
          <li><a href="#" className='text-dark'><FaTachometerAlt /> Dashboard</a></li>
          <li><a href="#" className='text-dark'><FaFileAlt /> Reports</a></li>
          <li><a href="#" className='text-dark'><FaUser /> Patients</a></li>
          <li><a href="#" className='text-dark'><FaCalendarAlt /> Appointments</a></li>
          <li><a href="#" className='text-dark'><FaUserMd /> Doctors</a></li>
          <li><a href="#" className='text-dark'><FaCogs /> Settings</a></li>
          <li><a href="#" className='text-dark' onClick={handleLogout}><FaSignOutAlt /> Logout</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

